<template>
  <v-container fluid class="mt-2" style="height: 500px; width: 100%;">
    <v-form v-model="valida" dense class="mt-4 pa-4" style="background-color: rgb(248 248 248); border-radius: 12px;">
      <v-row>
        <v-col cols="12">
          <label>
            {{ $t("components.views.settings.posting_rules.post_radius.description") }}</label
          >
        </v-col>
      </v-row>
      <v-row class="mb-0" justify="center">
        <v-col class="text-left" cols="4">
          <span class="text-h2 font-weight-light" v-text="ratio"></span>
          <span class="subheading font-weight-light mr-1">Km</span>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="11">
          <v-slider v-model="ratio" track-color="primary" min="100" max="500" always-dirty>
            <template v-slot:prepend>
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-icon class="mr-1" v-bind="attrs" v-on="on" color="primary" @click="decrement">
                    fa-solid fa-minus
                  </v-icon>
                </template>
                <span>
                  {{ $t("components.views.settings.posting_rules.post_radius.increase") }}</span
                >
              </v-tooltip>
            </template>
            <template v-slot:append>
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-icon class="mr-1" v-bind="attrs" v-on="on" color="primary" @click="increment">
                    fa-solid fa-plus
                  </v-icon>
                </template>
                <span>
                  {{ $t("components.views.settings.posting_rules.post_radius.decrease") }}</span
                >
              </v-tooltip>
            </template>
          </v-slider>
        </v-col>
      </v-row>
    </v-form>

    <v-card-actions class="border-top " style="margin-top: 280px;">
      <v-spacer />
      <v-btn
        color="light-green darken-3"
        depressed
        @click.stop="saveCounterOffer()"
        class="white--text"
        :disabled="!valida"
      >
        <v-icon small class="fa-solid fa-bookmark mr-1" />
        {{ $t("components.views.settings.posting_rules.post_radius.save") }}
      </v-btn>
    </v-card-actions>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import validaciones from "@/plugins/validate";
export default {
  data() {
    return {
      ...validaciones,
      percent: "",
      ratio: "",
      update: true,
      id: 0,
      valida: false,
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
    idCompany() {
      if (this.user !== undefined) {
        let unlockUser = JSON.parse(this.user);
        return unlockUser.user.idCompany;
      }
    },
    typeUser() {
      if (this.user !== undefined) {
        let unlockUser = JSON.parse(this.user);
        return unlockUser.user.typeUser;
      }
    },
  },
  methods: {
    ...mapActions("snackbar", ["showSnack"]),
    ...mapActions("loader", ["showLoader"]),

    decrement() {
      this.ratio--;
    },

    increment() {
      this.ratio++;
    },

    async loadPostRadius() {
      await this.axios
        .get(`/PerimeterOffer/Perimeter/Get/${this.idCompany}`)
        .then((data) => {
          this.ratio = data.data.data.percentage / 1000;
          if (this.ratio) {
            this.update = true;
          }
        })
        .catch((error) => {});
    },

    async saveCounterOffer() {
      this.showLoader({
        show: true,
      });

      let ratio = parseInt(this.ratio) * 1000;

      if (!this.update) {
        await this.axios
          .post(`/CounterOffer/Save/${ratio}`)
          .then(() => {
            this.showSnack({
              text: this.$t("components.views.settings.posting_rules.post_radius.save_success"),
              title: this.$t("components.views.settings.posting_rules.post_radius.success"),
              name: "success",
            });
          })
          .catch((error) => {
            this.showSnack({
              text: error.response.data,
              title: this.$t("components.views.settings.posting_rules.post_radius.save_error"),
              name: "error",
            });
          })
          .finally(() => {
            this.showLoader({
              show: false,
            });
          });
      } else {
        let params = {
          idCompany: this.idCompany,
          percentage: ratio,
        };

        await this.axios
          .put(`/PerimeterOffer/Perimeter/Update`, params)
          .then(() => {
            this.showSnack({
              text: this.$t("components.views.settings.posting_rules.post_radius.save_success"),
              title: this.$t("components.views.settings.posting_rules.post_radius.success"),
              name: "success",
            });
          })
          .catch((error) => {
            this.showSnack({
              text: error.response.data,
              title: this.$t("components.views.settings.posting_rules.post_radius.save_error"),
              name: "error",
            });
          })
          .finally(() => {
            this.showLoader({
              show: false,
            });
          });
      }
    },
  },
  metaInfo() {
    return {
      title: this.$t(
        "components.views.settings.posting_rules.post_radius.publication_radius"
      ),
    };
  },
  mounted() {
    this.loadPostRadius();
  },
};
</script>

<style scoped>
@import './PostRadiusStyle.scss';
</style>
